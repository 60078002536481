<template>
  <div
    v-if="this.$route.matched.length > 2"
    class="animated fadeIn"
  >
    <router-view />
  </div>
  <div
    v-else
    class="animated fadeIn"
  >
    <b-card no-body>
      <b-card-header v-b-toggle.filterBody>
        Фильтр
      </b-card-header>
      <b-collapse
        id="filterBody"
        visible
        class="card-body"
      >
        <b-form @submit.prevent>
          <b-row>
            <b-col md="3">
              <b-form-group label="Код рейса">
                <b-form-input
                  v-model="filter.name"
                  type="text"
                  autocomplete="false"
                  @input="updateFilter('name')"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                class="form-group-el-select"
                label="Тип перевозки"
              >
                <el-select
                  v-model="filter.transportation"
                  class="form-control"
                  name="transportation"
                  :loading="loading"
                  :disabled="filter.category === 'cancelled'"
                  @input="updateFilter('transportation')"
                >
                  <el-option
                    v-for="item in types"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="Маршрут"
              >
                <el-select-clearable
                  v-model="filter.route_way"
                  class="form-control"
                  name="route-way"
                  placeholder="Начните вводить название маршрута"
                  :multiple="true"
                  clearable
                  :filterable="true"
                  :remote="true"
                  reserve-keyword
                  :remote-method="customersRoutesRouteWaysList"
                  :loading="loading"
                  @input="updateFilter('route_way')"
                  @clear="customersRoutesRouteWaysList"
                >
                  <el-option
                    v-for="item in routeWays"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select-clearable>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="Перевозчик"
              >
                <el-select-clearable
                  v-model="filter.contractor"
                  class="form-control"
                  name="contractor"
                  placeholder="Начните вводить название компании перевозчика"
                  :multiple="true"
                  clearable
                  :filterable="true"
                  :remote="true"
                  reserve-keyword
                  :remote-method="customersRoutesContractorsList"
                  :loading="loading"
                  @input="updateFilter('contractor')"
                  @clear="customersRoutesContractorsList"
                >
                  <el-option
                    v-for="item in contractors"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select-clearable>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Дата от">
                <el-date-picker-input
                  v-model="filter.date_from"
                  type="datetime"
                  autocomplete="false"
                  format="dd.MM.yyyy HH:mm"
                  :picker-options="pickerOptions"
                  @change="updateDateFilter('date_from')"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Дата до">
                <el-date-picker-input
                  v-model="filter.date_to"
                  type="datetime"
                  autocomplete="false"
                  format="dd.MM.yyyy HH:mm"
                  :picker-options="pickerOptions"
                  @change="updateDateFilter('date_to')"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="Водитель"
              >
                <el-select-clearable
                  v-model="filter.driver"
                  class="form-control"
                  name="driver"
                  placeholder="Начните вводить фамилию, имя и/или отчество"
                  :multiple="true"
                  clearable
                  :filterable="true"
                  :remote="true"
                  reserve-keyword
                  :remote-method="customersRoutesDriversList"
                  :loading="loading"
                  @input="updateFilter('driver')"
                  @clear="customersRoutesDriversList"
                >
                  <el-option
                    v-for="item in drivers"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select-clearable>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="TC"
              >
                <el-select-clearable
                  v-model="filter.vehicle"
                  class="form-control"
                  name="vehicle"
                  placeholder="Начните вводить название марки и/или ГРН"
                  :multiple="true"
                  clearable
                  :filterable="true"
                  :remote="true"
                  reserve-keyword
                  :remote-method="customersRoutesTransportVehiclesList"
                  :loading="loading"
                  @input="updateFilter('vehicle')"
                  @clear="customersRoutesTransportVehiclesList"
                >
                  <el-option
                    v-for="item in vehicles"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select-clearable>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Группа ответственных"
                class="form-group-el-select"
              >
                <el-select-clearable
                  v-model="filter.manager"
                  class="form-control"
                  name="manager"
                  placeholder="Начните вводить название"
                  :multiple="true"
                  clearable
                  :filterable="true"
                  :remote="true"
                  reserve-keyword
                  :remote-method="customersRoutesManagersList"
                  :loading="loading"
                  @input="updateFilter('manager')"
                  @clear="customersRoutesManagersList"
                >
                  <el-option
                    v-for="item in managers"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select-clearable>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                class="form-group-el-select"
                label="Статус"
              >
                <el-select
                  v-model="filter.is_draft"
                  class="form-control"
                  name="is-draft"
                  placeholder=""
                  clearable
                  :loading="loading"
                  @input="updateFilter('is_draft')"
                >
                  <el-option
                    v-for="item in statuses"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                class="form-group-el-select"
                label="Помеченные на удаление"
              >
                <el-select
                  v-model="filter.is_cancel"
                  class="form-control"
                  name="is-cancel"
                  placeholder=""
                  clearable
                  :loading="loading"
                  @input="updateFilter('is_cancel')"
                >
                  <el-option
                    v-for="item in cancelStates"
                    :key="'cancelStates'+item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="Точка отправления"
              >
                <el-select
                  v-model="filter.first_point"
                  class="form-control"
                  name="first_point"
                  placeholder="Введите точку отправления"
                  clearable
                  :filterable="true"
                  :remote="true"
                  reserve-keyword
                  :loading="loading"
                  :remote-method="firstDepartmentsList"
                  @input="updateFilter('first_point')"
                  @clear="firstDepartmentsList"
                >
                  <el-option
                    v-for="item in firstDepartments"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="Конечная точка"
              >
                <el-select
                  v-model="filter.last_point"
                  class="form-control"
                  name="last_point"
                  placeholder="Введите конечную точку"
                  clearable
                  reserve-keyword
                  :filterable="true"
                  :loading="loading"
                  :remote="true"
                  :remote-method="lastDepartmentsList"
                  @input="updateFilter('last_point')"
                  @clear="lastDepartmentsList"
                >
                  <el-option
                    v-for="item in lastDepartments"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="На верификации"
              >
                <b-form-checkbox
                  id="accelerated"
                  v-model="filter.is_dirty"
                  name="accelerated"
                  @change="updateDateFilter('is_dirty')"
                >
                  Да
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="Грузопровод"
              >
                <b-form-checkbox
                  id="isCargoPipeline"
                  v-model="filter.cargo_flow"
                  name="cargo_flow"
                  @change="updateDateFilter('cargo_flow')"
                >
                  Да
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-collapse>
    </b-card>
    <b-card no-body>
      <b-card-header>
        Рейсы
        <div class="card-header-actions">
          <small class="text-muted">Дата последнего обновления {{ lastRefreshTime }} </small>
          <b-button
            :disabled="loading"
            variant="primary"
            size="sm"
            class="ml-1"
            @click="$refs.table.refresh()"
          >
            <i
              :class="{'fa-spin' : loading}"
              class="fa fa-refresh"
            />&nbsp;Обновить
          </b-button>
          <b-button
            v-if="$store.getters.isAdminOrManager"
            variant="primary"
            size="sm"
            to="/flights/customer/add"
            class="ml-2"
          >
            <i class="fa fa-plus" />&nbsp;Добавить
          </b-button>
        </div>
      </b-card-header>
      <b-card-body :class="{'loading' : loading}">
        <flight-legend :transportation-types="types" />
        <v-server-table
          id="dataTable"
          ref="table"
          :columns="columns"
          :options="options"
          :theme="theme"
          @loaded="loading = false"
        >
          <span
            slot="routeCode"
            slot-scope="props"
          >
            {{ props.row.routeCode }}<br>
            <a
              v-if="props.row.tender"
              :href="'/customers/id' + $store.state.user.ownerId + '/tender/id' + props.row.tender.id"
            >ссылка на тендер</a>
          </span>
          <span
            slot="transportationNames"
            slot-scope="props"
          >
            <div
              v-if="props.row.transportation.name"
              :style="`backgroundColor: ${props.row.transportation.color};`"
              class="flight-card"
            >
              <span :style="props.row.transportation.color == null ? 'color: black;' : 'color: white;'">
                {{ props.row.transportation.name }}
                <br>
              </span>
            </div>
          </span>
          <span
            slot="transportationTypes"
            slot-scope="props"
          >
            <span v-if="props.row.transportationType">
              <span>{{ props.row.transportationType.name }}</span>
            </span><br>
            <span v-if="props.row.cargoFlow" :class="{ 'cagro-flow': props.row.cargoFlow }">Грузопровод</span>
          </span>
          <span
            slot="currentPoints"
            slot-scope="props"
          >
            <span v-if="props.row.routeCurrentState && props.row.routeCurrentState.currentPoints && props.row.routeCurrentState.currentPoints.length > 0">
              <span
                v-for="(currentPoint, index) in props.row.routeCurrentState.currentPoints"
                :key="'index +' + index"
                class="routes-current-points"
              >{{ currentPoint.department.name }}<br></span>
            </span>
          </span>
          <span
            slot="arrivalTime"
            slot-scope="props"
          >
            <span v-if="props.row.routeCurrentState">
              {{ getRouteStatus(props.row.routeCurrentState.status) }}
              <span><b>{{ formatDatetimeWithOriginalTimezone(props.row.routeCurrentState.arrivalTime) }}</b></span>
            </span>
          </span>
          <span
            slot="routeStatus"
            slot-scope="props"
          >
            <span v-if="props.row.routeCurrentState && props.row.routeCurrentState.currentPoints && props.row.routeCurrentState.currentPoints.length > 0">
              <b>
                <route-counter
                  :date="props.row.routeCurrentState.counter"
                  :current-point="props.row.routeCurrentState.currentPoints.slice(-1)[0]"
                  :status="props.row.routeCurrentState.status"
                />
              </b>
            </span>
          </span>
          <span
            slot="planDateOfFirstPointArrive"
            slot-scope="props"
          >{{ formatDatetimeWithOriginalTimezone(props.row.planDateOfFirstPointArrive) }}</span>
          <span
            slot="drivers"
            slot-scope="props"
          >
            <span
              v-if="props.row.driverOne && !props.row.cargoFlow"
            >{{ props.row.driverOne.surname }} {{ props.row.driverOne.name }} <span v-if="props.row.driverOne.patronymic">{{ props.row.driverOne.patronymic }}</span></span>
            <span
              v-if="props.row.driverTwo && !props.row.cargoFlow"
            >/ {{ props.row.driverTwo.surname }} {{ props.row.driverTwo.name }} <span v-if="props.row.driverTwo.patronymic">{{ props.row.driverTwo.patronymic }}</span></span>
            <span v-if="props.row.cargoFlow">
              <span v-if="props.row.cargoPipelines && props.row.cargoPipelines.length > 0">
                {{ `${props.row.cargoPipelines.find(item => item.rowNumber == 1).driver.surname} 
                    ${props.row.cargoPipelines.find(item => item.rowNumber == 1).driver.name} 
                    ${props.row.cargoPipelines.find(item => item.rowNumber == 1).driver.patronymic}` }}
              </span>
            </span>
          </span>
          <span
            slot="naRouteOwner.name"
            slot-scope="props"
          >
            <span v-if="props.row.naRouteOwner && props.row.naRouteOwner.name">{{ props.row.naRouteOwner.name }}</span>
            <span v-else>Группа ответственных не представлена в системе ЕТП</span>
          </span>
          <span
            slot="freightSumm"
            slot-scope="props"
          >
            <span v-if="props.row.freightSumm">{{ numberRUFormat(props.row.freightSumm) }} руб.</span>
            <span v-else>Н/Д</span>
          </span>
          <span
            slot="status"
            slot-scope="props"
          >
            <span
              v-if="props.row.isCancel"
              class="text-danger"
            >Пометка на удаление<br></span>
            <span
              v-if="!props.row.isDraft"
              class="text-success"
            >Подтвержден</span>
            <span v-else>
              <span
                v-if="props.row.isDraft"
                class="text-secondary"
              >Не&nbsp;подтвержден&ensp;</span>
              <span
                v-if="props.row.isDirty"
                class="text-danger"
              >На&nbsp;верификации&ensp;</span>
              <span
                v-if="props.row.auction && props.row.auction.status !== 5 && props.row.auction.status !== 6"
                class="text-primary"
              >На&nbsp;аукционе&ensp;</span>
              <span
                v-if="props.row.isReady"
                class="text-success"
              >Готов&nbsp;к&nbsp;отписке&ensp;</span>
            </span>
            <span
              v-if="props.row.contractorDisclaimer && props.row.contractorDisclaimer.status === 0"
              class="text-danger"
            ><br>Заявка на отказ</span>
          </span>
          <span
            slot="actions"
            slot-scope="props"
            class="d-flex flex-wrap flex-column align-items-center justify-content-center"
          >
            <!--a v-if="props.row.isDraft"
              v-b-tooltip.hover.bottom="'Отписать рейс'"
              @click="confirmReadyFlight(props.row.id, props.row.contractor.name, props.row.isReady)"
              class="fa fa-mail-forward fa-lg text-success table-action-button"
            ></a-->
            <a
              v-if="$store.getters.isAdminOrManager && !props.row.isCancel && props.row.isDraft"
              v-b-tooltip.hover.bottom="'Редактировать'"
              :href="'/flights/customer/flight/id' + props.row.id"
              class="fa fa-pencil fa-lg text-primary table-action-button mt-1 mb-2"
            />
            <a
              v-b-tooltip.hover.bottom="'Просмотреть'"
              :href="'/customers/id' + $store.state.user.ownerId + '/flight/id' + props.row.id"
              class="fa fa-eye fa-lg text-secondary table-action-button mb-2"
            />
            <a 
            v-if="props.row.comment"
            v-b-tooltip.hover.bottom="'Прочитать комментарий'"
            class="fa fa-exclamation fa-lg text-danger table-action-button mb-2"
            @click="showCommentModal(props.row)"
            />
            <!--a
              v-if="props.row.isCancel"
              v-b-tooltip.hover.bottom="'Снять пометку на удаление'"
              class="fa fa-unlock-alt fa-lg text-success table-action-button"
              @click="removeDeletionMark(props.row.id)"
            /-->
            <a
              v-if="!props.row.cargoFlow && $store.getters.isAdminOrManager && props.row.routeCode && props.row.naRouteOwner && !props.row.contractor && !props.row.auction && props.row.isDraft"
              v-b-tooltip.hover.bottom="'Перевести в Аукцион'"
              class="fa fa-gavel fa-lg text-success table-action-button"
              @click="customersAuctionCreate(props.row)"
            />
            <!--a
              v-b-tooltip.hover.bottom="'Удалить'"
              @click="confirmDeleteFlight(props.row.id)"
              class="fa fa-close fa-lg text-danger table-action-button"
              v-if="$store.getters.isAdmin"
            ></a-->
          </span>
        </v-server-table>
      </b-card-body>
    </b-card>

    <b-modal
    v-model="showComment"
    :title="'Комментарий к рейсу ' + routeCode"
    class="modal-warning"
    cancel-title="Назад"
    ok-only
    >
    <div> 
    <span class="font-weight-bold">{{routeName}}:</span>
    {{comment}}
    </div>
    </b-modal>

    <b-modal
      v-model="confirmReadyFlightModal.show"
      :title="confirmReadyFlightModal.title"
      class="modal-warning"
      cancel-title="Назад"
      ok-title="Да"
      @ok="readyFlight(confirmReadyFlightId)"
    >
      {{ confirmReadyFlightModal.desc }}
    </b-modal>
  </div>
</template>

<script>
import ElDatePickerInput from '../../components/ElDatePickerInput.vue';
import ElSelectClearable from '../../components/ElSelectClearable.vue';
import Vue from 'vue';
import {ServerTable, Event} from 'vue-tables-2';
import notifications from '../../components/mixins/notifications';
import {
  customersRoutesList,
  customersRouteSetDraft,
  customersRouteUncancel,
  customersRouteDelete,
  customersAuctionCreate,
  customersRoutesTransportVehiclesList,
  customersRoutesDriversList,
  customersRoutesRouteWaysList,
  customersRoutesContractorsList,
  customersRoutesManagersList,
  routesTransportationTypesList,
  routeOwnersList,
  departmentsList,
} from '../../services/api';
import moment from 'moment';
import tz from 'moment-timezone';
import {arrayStringToNumber, numberToRUFormat} from '../../components/mixins/helpers';
import {queryToUrl, URLToQuery} from '../../services/http';
import FlightLegend from '../../components/FlightLegend.vue';
import RouteCounter from '@/components/RouteCounter.vue';

Vue.use(ServerTable);

export default {
  name: 'CustomerFlights',
  components: {
    RouteCounter,
    ServerTable,
    Event,
    FlightLegend,
    ElSelectClearable,
    ElDatePickerInput,
  },
  mixins: [notifications],
  data: function() {
    return {
      lastRefreshTime: null,
      statuses: [
        {value: null, text: 'Все'},
        {value: 0, text: 'Подтвержден'},
        {value: 1, text: 'Не подтвержден'},
      ],
      cancelStates: [
        {value: null, text: 'Все'},
        {value: 0, text: 'Не помечен'},
        {value: 1, text: 'Помечен'},
      ],
      routeWays: [],
      vehicles: [],
      comment: null,
      routeId: null,
      routeCode: null,
      routeName: null,
      types: [],
      managers: [],
      drivers: [],
      contractors: [],
      firstDepartments: [],
      lastDepartments: [],
      filter: {
        route_way: [],
        contractor: [],
        date_from: null,
        date_to: null,
        transportation: null,
        driver: [],
        vehicle: [],
        first_point: [],
        last_point: [],
        manager: [],
        is_draft: null,
        is_dirty: false,
        cargo_flow: false,
        is_cancel: 0,
        page: 1,
        limit: 100,
      },
      confirmReadyFlightId: null,
      showComment: false,
      confirmReadyFlightModal: {
        show: false,
        title: 'Предупреждение',
        desc: '',
      },
      loading: false,
      columns: [
        'routeCode',
        'routeWay.name',
        'transportationNames',
        'transportationTypes',
        'planDateOfFirstPointArrive',
        'currentPoints',
        'arrivalTime',
        'routeStatus',
        'contractor.name',
        'drivers',
        'transport.name',
        'trailer.name',
        'naRouteOwner.name',
        'freightSumm',
        'status',
        'actions',
      ],
      options: {
        customFilters: [
          'name',
          'route_way',
          'first_point',
          'last_point',
          'transportation',
          'transportationType',
          'contractor',
          'date_from',
          'date_to',
          'driver',
          'vehicle',
          'manager',
          'is_draft',
          'is_dirty',
          'cargo_flow',
          'is_cancel',
        ],
        requestFunction: (data) => {
          this.loading = true;
          this.filter.page = data.page;
          this.filter.limit = data.limit;
          this.lastRefreshTime = moment().format('DD.MM.YYYY HH:mm:ss');
          return customersRoutesList(this.$store.state.user.ownerId, Object.assign(data, this.updateRouteParams()));
        },
        responseAdapter({data}) {
          data.items.forEach((item) => {
            if (item.routeCurrentState && item.routeCurrentState.currentPoints && item.routeCurrentState.currentPoints.length > 0) {
              item.routeCurrentState.currentPoints = item.routeCurrentState.currentPoints.sort((a, b) => a.rowNumber - b.rowNumber);
            }
          });
          return {
            data: data.items,
            count: data.totalCount,
          };
        },
        headings: {
          'id': 'ID',
          'routeCode': 'Код рейса',
          'routeWay.name': 'Маршрут рейса',
          'transportationNames': 'Тип перевозки',
          'transportationTypes': 'Вид перевозки',
          'planDateOfFirstPointArrive': 'План прибытия в первую точку',
          'currentPoints': 'Текущая точка(плечо)',
          'arrivalTime': 'Статус движения рейса',
          'routeStatus': 'Он-лайн счетчик',
          'routeCode.name': 'Маршрут рейса',
          'contractor.name': 'Перевозчик',
          'drivers': 'Водители',
          'transport.name': 'Автомобиль',
          'trailer.name': 'Прицеп',
          'naRouteOwner.name': 'Группа ответственных',
          'freightSumm': 'Стоимость рейса',
          'status': 'Статус',
          'actions': '',
        },
        sortable: [],
        filterable: [],
        sortIcon: {
          base: 'fa',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc',
          is: 'fa-sort',
        },
        pagination: {
          chunk: 10,
          edge: true,
          nav: 'fixed',
        },
        perPage: 100,
        texts: {
          count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
          first: 'Первая',
          last: 'Последняя',
          filter: 'Фильтр:',
          filterPlaceholder: 'Поисковый запрос',
          limit: 'Записи:',
          page: 'Страница:',
          noResults: 'Нет совпадающих записей',
          filterBy: 'Фильтр по {column}',
          loading: 'Загрузка...',
          defaultOption: 'Выбор {column}',
          columns: 'Столбцы',
        },
      },
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      useVuex: false,
      theme: 'bootstrap4',
      template: 'default',
    };
  },
  computed: {
    getRouteStatus(status) {
      return (status) => {
        switch (status) {
        case 0:
          return 'Ожидает прибытия';
          break;
        case 1:
          return 'Прибыл (ожидает погрузки)';
          break;
        case 2:
          return 'В Погрузке';
          break;
        case 3:
          return 'Прибыл (ожидает погрузки/разгрузки)';
          break;
        case 4:
          return 'В погрузке или разгрузке';
          break;
        case 5:
          return 'Прибыл (ожидает разгрузку)';
          break;
        case 6:
          return 'На разгрузке';
          break;
        case 7:
          return 'Завершен';
          break;
        default:
          break;
        }
      };
    },
  },
  mounted() {
    this.routesTransportationTypesList();
    this.customersRoutesRouteWaysList();
    this.customersRoutesContractorsList();
    this.customersRoutesDriversList();
    this.customersRoutesTransportVehiclesList();
    this.customersRoutesManagersList();
    this.firstDepartmentsList();
    this.lastDepartmentsList();
    // this.firstDepartmentsList();
    // this.lastDepartmentsList();
    const queries = this.$router.currentRoute.query;
    /**
     * Если в URL есть параметр 'limit'
     * то перекидываем таблицу на соответствующее
     * параметру значение
     * */
    if (queries.limit) {
      this.$refs.table.setLimit(queries.limit);
    }
    /**
     * Если в URL есть параметр 'page'
     * то перекидываем таблицу на соответствующее
     * параметру значение
     * */
    if (queries.page) {
      this.$refs.table.setPage(queries.page);
    }
    /**
     * Записываем параметр при
     * изменении страницы таблицы
     * и записываем в URL
     * */
    const that = this;
    Event.$on('vue-tables.pagination', (data) => {
      that.filter.page = data;
      that.updateRouteParams();
    });
    Event.$on('vue-tables.limit', (data) => {
      this.filter.page = 1;
      this.filter.limit = data;
      this.updateRouteParams();
    });
  },
  created() {
    this.initRouteParams();
  },
  methods: {
    formatDatetimeWithOriginalTimezone(date) {
      const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return date ? moment(date, 'YYYY-MM-DDTHH:mm').tz(clientTimezone).format('DD.MM.YYYY HH:mm') : '';
    },
    formatDatetimeWithClientTimezone(date) {
      const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return date ? moment.tz(date, clientTimezone).format('DD.MM.YYYY HH:mm') : '';
    },
    numberRUFormat(value) {
      return numberToRUFormat(value);
    },
    updateFilter(field) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        const val = this.filter[field] !== '' ? this.filter[field] : null;
        Event.$emit('vue-tables.filter::' + field, val);
      }, 800);
    },
    updateDateFilter(field) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        const val = this.filter[field] ? moment(this.filter[field]).format('YYYY-MM-DDTHH:mm') : null;
        Event.$emit('vue-tables.filter::' + field, val);
      }, 800);
    },
    showCommentModal(route) {
      this.showComment = true;
      if (route.comment.comment) {
        this.comment = route.comment.comment;
      }
      this.routeId = route.id;
      this.routeCode = route.routeCode;
      this.routeName = route.routeWay.code;   
    },
    confirmReadyFlight(id, name, ready) {
      this.confirmReadyFlightId = id;
      this.confirmReadyFlightModal = {
        show: true,
        title: 'Предупреждение',
      };
      if (!ready) {
        this.confirmReadyFlightModal.desc = '«' + name + '», еще не подтвердил готовность к выполнению рейса, Вы Уверены что хотите произвести отписку?';
      } else {
        this.confirmReadyFlightModal.desc = 'Вы уверены что хотите произвести отписку?';
      }
    },
    async readyFlight(routeId) {
      this.loading = true;
      const response = await customersRouteSetDraft(this.$store.state.user.ownerId, routeId);
      if (response && response.status === 200) {
        this.showSuccess('Рейс подтвержден');
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
    confirmDeleteFlight(routeId) {
      this.$bvModal
        .msgBoxConfirm('Вы уверены что хотите удалить рейс?', {
          okTitle: 'Да',
          cancelTitle: 'Назад',
        })
        .then((confirm) => {
          if (confirm) {
            this.showSuccess('Рейс удален');
            this.deleteFlight(routeId);
          }
        });
    },
    async removeDeletionMark(routeId) {
      this.loading = true;
      const response = await customersRouteUncancel(this.$store.state.user.ownerId, routeId);
      if (response && response.status === 200) {
        this.showSuccess('Рейс востановлен');
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
    async deleteFlight(routeId) {
      this.loading = true;
      const response = await customersRouteDelete(this.$store.state.user.ownerId, routeId);
      if (response && response.status === 200) {
        this.showSuccess('Рейс удален');
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
    async routesTransportationTypesList() {
      this.loading = true;
      const response = await routesTransportationTypesList({limit: 100});
      if (response && response.status === 200) {
        this.types = response.data.items.map((item) => {
          return {value: item.id, text: item.name, color: item.color, description: item.description};
        });
        this.types.unshift({value: null, text: 'Все'});
      }
      this.loading = false;
    },
    async firstDepartmentsList(name) {
      this.loading = true;
      const params = {limit: 100};
      if (name) {
        params.name = name;
      }
      const response = await departmentsList(params);
      if (response && response.status === 200) {
        this.firstDepartments = response.data.items.map((item) => {
          return {
            value: item.id,
            text: item.code,
          };
        });
      }
      this.loading = false;
    },
    async lastDepartmentsList(name) {
      this.loading = true;
      const params = {limit: 100};
      if (name) {
        params.name = name;
      }
      const response = await departmentsList(params);
      if (response && response.status === 200) {
        this.lastDepartments = response.data.items.map((item) => {
          return {
            value: item.id,
            text: item.code,
          };
        });
      }
      this.loading = false;
    },
    async customersAuctionCreate(route) {
      this.loading = true;
      const response = await customersAuctionCreate(this.$store.state.user.ownerId, {
        auctionRoutes: [{
          route: {
            id: route.id,
            planDateOfFirstPointArrive: route.planDateOfFirstPointArrive,
            planDateOfFirstPointLoading: route.planDateOfFirstPointLoading,
            boostFlag: route.boostFlag,
          },
        }],
      });
      if (response && response.status === 201) {
        this.showSuccess('Аукцион создан');
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
    async customersRoutesRouteWaysList(name) {
      this.loading = true;
      const params = {limit: 100};
      if (name) {
        params.name = name;
      }
      const response = await customersRoutesRouteWaysList(this.$store.state.user.ownerId, params);
      if (response && response.status === 200) {
        this.routeWays = response.data.items.map((item) => {
          return {
            value: item.id,
            text: item.name,
          };
        });
      }
      if (this.filter.route_way.length > 0) {
        this.customersRoutesSelectedRouteWaysList();
      }
      this.loading = false;
    },
    async customersRoutesSelectedRouteWaysList() {
      this.loading = true;
      const selectedRouteWays = {};
      this.filter.route_way.map((item, index) => {
        selectedRouteWays['id[' + index + ']'] = item;
      });
      const response = await customersRoutesRouteWaysList(this.$store.state.user.ownerId, selectedRouteWays);
      if (response && response.status === 200) {
        response.data.items.forEach((item) => {
          this.routeWays = this.routeWays.filter((x) => x.value !== item.id);
          this.routeWays.unshift({
            value: item.id,
            text: item.name,
          });
        });
      }
      this.loading = false;
    },
    async customersRoutesContractorsList(query) {
      this.loading = true;
      const params = {limit: 100};
      if (query) {
        params.query = query;
      }
      const response = await customersRoutesContractorsList(this.$store.state.user.ownerId, params);
      if (response && response.status === 200) {
        this.contractors = response.data.items.map((item) => {
          return {
            value: item.id,
            text: item.name,
          };
        });
      }
      if (this.filter.contractor.length > 0) {
        this.customersRoutesSelectedContractorsList();
      }
      this.loading = false;
    },
    async customersRoutesSelectedContractorsList() {
      this.loading = true;
      const selectedContractors = {};
      this.filter.contractor.map((item, index) => {
        selectedContractors['id[' + index + ']'] = item;
      });
      const response = await customersRoutesContractorsList(this.$store.state.user.ownerId, selectedContractors);
      if (response && response.status === 200) {
        response.data.items.forEach((item) => {
          this.contractors = this.contractors.filter((x) => x.value !== item.id);
          this.contractors.unshift({
            value: item.id,
            text: item.name,
          });
        });
      }
      this.loading = false;
    },
    async customersRoutesDriversList(fio) {
      this.loading = true;
      const params = {limit: 100};
      if (fio) {
        params.fio = fio;
      }
      const response = await customersRoutesDriversList(params);
      if (response && response.status === 200) {
        this.drivers = response.data.items.map((item) => {
          let fio = item.surname + ' ' + item.name;
          if (item.patronymic) {
            fio = fio + ' ' + item.patronymic;
          }
          return {
            value: item.id,
            text: fio,
          };
        });
      }
      if (this.filter.driver.length > 0) {
        this.customersRoutesSelectedDriversList();
      }
      this.loading = false;
    },
    async customersRoutesSelectedDriversList() {
      this.loading = true;
      const selectedDrivers = {};
      this.filter.driver.map((item, index) => {
        selectedDrivers['id[' + index + ']'] = item;
      });
      const response = await customersRoutesDriversList(selectedDrivers);
      if (response && response.status === 200) {
        response.data.items.forEach((item) => {
          this.drivers = this.drivers.filter((x) => x.value !== item.id);
          let fio = item.surname + ' ' + item.name;
          if (item.patronymic) {
            fio = fio + ' ' + item.patronymic;
          }
          this.drivers.unshift({
            value: item.id,
            text: fio,
          });
        });
      }
      this.loading = false;
    },
    async customersRoutesTransportVehiclesList(query) {
      this.loading = true;
      const params = {limit: 100};
      if (query) {
        params.query = query;
      }
      const response = await customersRoutesTransportVehiclesList(this.$store.state.user.ownerId, params);
      if (response && response.status === 200) {
        this.vehicles = response.data.items.map((item) => {
          return {
            value: item.id,
            text: item.name,
          };
        });
      }
      if (this.filter.vehicle.length > 0) {
        this.customersRoutesSelectedTransportVehiclesList();
      }
      this.loading = false;
    },
    async customersRoutesSelectedTransportVehiclesList() {
      this.loading = true;
      const selectedTransportVehicles = {};
      this.filter.vehicle.map((item, index) => {
        selectedTransportVehicles['id[' + index + ']'] = item;
      });
      const response = await customersRoutesTransportVehiclesList(
        this.$store.state.user.ownerId,
        selectedTransportVehicles,
      );
      if (response && response.status === 200) {
        response.data.items.forEach((item) => {
          this.vehicles = this.vehicles.filter((x) => x.value !== item.id);
          this.vehicles.unshift({
            value: item.id,
            text: item.name,
          });
        });
      }
      this.loading = false;
    },
    async customersRoutesManagersList(query) {
      this.loading = true;
      const params = {limit: 100};
      if (query) {
        params.query = query;
      }
      const response = await routeOwnersList(params);
      if (response && response.status === 200) {
        this.managers = response.data.items.map((item) => {
          return {
            value: item.id,
            text: item.name,
          };
        });
      }
      if (this.filter.manager.length > 0) {
        this.customersRoutesSelectedManagersList();
      }
      this.loading = false;
    },
    async customersRoutesSelectedManagersList() {
      this.loading = true;
      const selectedManagers = {};
      this.filter.manager.map((item, index) => {
        selectedManagers['id[' + index + ']'] = item;
      });
      const response = await routeOwnersList(selectedManagers);
      if (response && response.status === 200) {
        response.data.items.forEach((item) => {
          this.managers = this.managers.filter((x) => x.value !== item.id);
          this.managers.unshift({
            value: item.id,
            text: item.name,
          });
        });
      }
      this.loading = false;
    },
    updateRouteParams() {
      const res = {};
      if (this.filter.transportation) {
        res.transportation = this.filter.transportation;
      }
      if (this.filter.route_way.length) {
        res.route_way = this.filter.route_way;
      }
      if (this.filter.contractor.length) {
        res.contractor = this.filter.contractor;
      }
      if (this.filter.date_from) {
        res.date_from = moment(this.filter.date_from).format('YYYY-MM-DDTHH:mm');
      }
      if (this.filter.date_to) {
        res.date_to = moment(this.filter.date_to).format('YYYY-MM-DDTHH:mm');
      }
      if (this.filter.driver.length) {
        res.driver = this.filter.driver;
      }
      if (this.filter.vehicle.length) {
        res.vehicle = this.filter.vehicle;
      }
      if (this.filter.manager.length) {
        res.manager = this.filter.manager;
      }
      if (this.filter.is_draft !== null) {
        res.is_draft = Number(this.filter.is_draft);
      }
      if (this.filter.is_dirty) {
        res.is_dirty = this.filter.is_dirty;
      }
      if (this.filter.is_cancel !== null) {
        res.is_cancel = this.filter.is_cancel;
      }
      if (this.filter.cargo_flow) {
        res.cargo_flow = this.filter.cargo_flow;
      }
      if (this.filter.name) {
        res.name = this.filter.name;
      }
      const storedFilter = {...this.filter};
      delete storedFilter.date_from;
      delete storedFilter.date_to;
      this.$store.commit('setFilterParams', {componentName: this.$options.name, params: storedFilter});
      if (this.filter.page && this.filter.page !== 1) {
        res.page = this.filter.page;
      } else {
        delete res.page;
      }
      history.pushState({}, document.title, this.$router.currentRoute.path);
      if (Object.keys(res).length !== 0) {
        const url = queryToUrl('', res);
        history.pushState(null, null, url);
      }
      return res;
    },
    /**
     * Запись параметров из URL в this.filter
     * */
    initRouteParams() {
      let params = URLToQuery(this.$route.fullPath);
      if (Object.keys(params).length !== 0) {
        if (params.hasOwnProperty('transportation')) {
          params.transportation = Number(params.transportation);
        }
        if (params.hasOwnProperty('route_way')) {
          params.route_way = arrayStringToNumber(params.route_way);
        }
        if (params.hasOwnProperty('contractor')) {
          params.contractor = arrayStringToNumber(params.contractor);
        }
        if (params.hasOwnProperty('driver')) {
          params.driver = arrayStringToNumber(params.driver);
        }
        if (params.hasOwnProperty('vehicle')) {
          params.vehicle = arrayStringToNumber(params.vehicle);
        }
        if (params.hasOwnProperty('manager')) {
          params.manager = arrayStringToNumber(params.manager);
        }
        if (params.hasOwnProperty('is_draft')) {
          params.is_draft = Number(params.is_draft);
        }
        if (params.hasOwnProperty('is_cancel')) {
          params.is_cancel = Number(params.is_cancel);
        }
        if (params.hasOwnProperty('page')) {
          params.page = Number(params.page);
        }
        Object.assign(this.filter, params);
      } else {
        params = this.$store.getters.getFilterParams(this.$options.name);
        if (!params) {
          params = {};
        }
        params.date_from = moment().format('YYYY-MM-DDT00:00:00.000Z');
        params.date_to = moment().format('YYYY-MM-DDT00:00:00.000Z');
        params.date_from = moment(params.date_from).add(8, 'hours').format();
        params.date_to = moment(params.date_to).add(32, 'hours').format();
        Object.assign(this.filter, params);
      }
    },
  },
};
</script>

<style lang="scss">
</style>
