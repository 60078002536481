<template>
  <span :class="textColor">{{ counter }}</span>
</template>

<script>
import moment from 'moment';

export default {
  name: 'RouteCounter',
  props: ['date', 'currentPoint', 'status'],
  data: function() {
    return {
      textColor: '',
      currentPointTime: moment.tz(moment(), this.currentPoint.department.timeZone),
    };
  },
  computed: {
    counter() {
      let diffTime = null;
      if (this.status === 0) {
        diffTime = moment(this.date).diff(this.currentPointTime);
      } else if (this.status === 1) {
        if (moment(this.currentPoint.planDateArrival) < moment(this.currentPoint.factDateArrival)) {
          diffTime = moment(this.currentPointTime).diff(this.currentPoint.factDateArrival);
        } else if (moment(this.currentPoint.planDateArrival) < moment(this.currentPointTime)) {
          diffTime = moment(this.currentPointTime).diff(this.currentPoint.planDateArrival);
        } else {
          diffTime = moment(this.currentPoint.planDateArrival).diff(this.currentPointTime);
        }
      } else {
        diffTime = moment(this.currentPointTime).diff(this.date);
      }
      const duration = moment.duration(diffTime);
      let days = null;
      if (this.status === 0) {
        days = moment(this.date).diff(this.currentPointTime, 'days');
      } else if (this.status === 1) {
        if (moment(this.currentPoint.planDateArrival) < moment(this.currentPoint.factDateArrival)) {
          days = moment(this.currentPointTime).diff(this.currentPoint.factDateArrival, 'days');
        } else if (moment(this.currentPoint.planDateArrival) < moment(this.currentPointTime)) {
          days = moment(this.currentPointTime).diff(this.currentPoint.planDateArrival, 'days');
        } else {
          days = moment(this.currentPoint.planDateArrival).diff(this.currentPointTime, 'days');
        }
      } else {
        days = moment(this.currentPointTime).diff(this.date, 'days');
      }
      const hrs = duration.hours();
      const mins = duration.minutes();
      if (days < 0 || hrs < 0 || mins < 0) {
        this.textColor = 'text-danger';
      } else if (this.status === 0) {
        this.textColor = 'text-success';
      } else if (moment(this.currentPoint.planDateArrival) > moment(this.currentPoint.factDateArrival) && moment(this.currentPoint.planDateArrival) > moment(this.currentPointTime) && (this.status === 1)) {
        this.textColor = 'text-success';
      } else {
        this.textColor = '';
      }
      return this.status === 7 ? '' : days + 'д. ' + hrs + 'ч. ' + mins + 'м.';
    },
  },
  mounted() {
    setInterval(() => {
      this.currentPointTime = moment.tz(moment(), this.currentPoint.department.timeZone);
    }, 1000);
  },
};
</script>

<style scoped>

</style>
