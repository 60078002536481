<template>
  <b-card
    v-b-toggle.legendCollapse
    class="flight-legend d-flex"
    no-body
  >
    <b-card-header>
      Типы перевозок
    </b-card-header>
    <b-collapse id="legendCollapse">
      <b-row class="flight-legend-cards">
        <template
          v-for="type in transportationTypes"
        >
          <b-card
            v-if="type.value != null"
            v-b-popover.hover.top="type.description"
            :style="`backgroundColor: ${type.color};`"
            class="flight-legend-cards__item flex-basis-30 d-flex justify-content-center"
          >
            <p :style="type.color == null ? 'color: black;' : 'color: white;'">
              {{ type.text }}
            </p>
          </b-card>
        </template>
      </b-row>
    </b-collapse>
  </b-card>
</template>

<script>
export default {
  name: 'FlightLegend',
  props: ['transportationTypes'],
  mounted() {
    console.log(this.transportationTypes);
  },
};
</script>

<style lang="scss" scoped>
#legendCollapse {
    padding: 15px;
}

.flight-legend {
    &-cards {
        display: flex;
        flex-wrap: nowrap;

        &__item {
            width: 16%;
            height: 60px;
            margin: 10px;
            color: white;
            font-weight: bold;
            transition: .1s;
            padding: 35px 0 25px 0;
            user-select: none;

            &_lg {
              width: 26%;
            }

            @media screen and (max-width: 1600px) {
                font-size: 13px;
            }

            @media screen and (max-width: 1355px) {
                padding: 45px 0 35px 0;
            }

            @media screen and (max-width: 736px) {
                width: calc(100% - 20px);
                font-size: 16px;
                padding: 0;
                height: 30px;
            }

            &:hover {
                transform: scale(1.1);
            }
        }

        &__item:not(.flex-basis-30) {
            @media screen and (max-width: 800px) {
                flex-basis: 46.7% !important;
            }
        }

        @media screen and (max-width: 1355px) {
            justify-content: center;
            flex-wrap: wrap;
        }

        @media screen and (max-width: 736px) {
            flex-direction: column;
        }
    }

    .flex-basis-22 {
        @media screen and (max-width: 1355px) {
            flex-basis: 22%;
        }
    }

    .flex-basis-30 {
        @media screen and (max-width: 1355px) {
            flex-basis: 30%;
        }
    }
}

.popover {
    font-size: 14px;
    font-weight: 600;
}
</style>
